<template>
  <transition name="slide">
    <div class="col-span-5 md:col-span-4">
      <div class="relative mx-0 md:mx-16 my-10 md:my-12 shadow-tab rounded-b-30 rounded-tr-30">
        <div class="absolute w-full flex text-center left-0 -top-9 justify-between">
          <div class="flex flex-row space-x-3">
            <router-link :to="{ name: 'ToTripwePay' }" :class="['rounded-t-10 font-bold text-white text-xl p-1 w-36 cursor-pointer transition-all duration-300', isActive === 'ToTripwePay' ? 'bg-primary' : 'bg-rounded']">
              Balance
            </router-link>
            <router-link :to="{ name: 'ToBank' }" :class="['rounded-t-10 font-bold text-white text-xl p-1 w-36 cursor-pointer transition-all duration-300', isActive === 'ToBank' ? 'bg-primary' : 'bg-rounded']">
              Bank
            </router-link>
          </div>
        </div>
        <div class="pt-10 px-4 md:px-9 pb-6">
            <transition name="fade">
               <router-view />
            </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'Transfers',
  computed: {
    isActive() {
      return this.$route.matched[2].name;
    },
  }
};
</script>